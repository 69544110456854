import React, { useState } from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import Section from '~components/Section'
import useSiteSettings from '~utils/useSiteSettings'
import DataViewer from '~utils/DataViewer'
import Image from '~components/Image'
import RichText from '~components/RichText'
import Button from '~components/Button'
import { mobile, tablet, desktop } from '~styles/global'
import {Facebook, Instagram, LinkedIn, LogoWhite, ChevronDown} from '~components/Svg'
import SanityLink from '~components/SanityLink'
import SlideDown from 'react-slidedown'
import 'react-slidedown/lib/slidedown.css'


const Footer = ({ className }) => {
	const [menuOpen, setMenuOpen] = useState(false)

	const {
		flags,
		acknowledgementText,
		acknowledgementButton,
		socialTitle,
		socialText,
		facebookUrl,
		instagramUrl,
		linkedInUrl,
		footerMenu,
		disclaimer,
		finePrintMenu,
		signUpLink
	} = useSiteSettings()

	return(
		<Wrap className={className}>
			{/* <DataViewer data={{
				flags,
				acknowledgementText,
				acknowledgementButton,
				socialTitle,
				socialText,
				facebookUrl,
				instagramUrl,
				linkedInUrl,
				footerMenu,
				disclaimer,
				finePrintMenu,
			}} name=""/> */}
			<Acknowledgement>
				<Flags>
					{flags.map(flag => (
						<Flag image={flag} key={flag.asset._id}/>
					))}
				</Flags>
				<AckText className='b2'>
					<RichText content={acknowledgementText}/>
				</AckText>
				{acknowledgementButton.text &&
					<AckButtonContainer>
						<Button bgColor={'white'} link={acknowledgementButton.link}>{acknowledgementButton.text}</Button>
					</AckButtonContainer>
				}
			</Acknowledgement>
			<Social>
				<SocialTitle>{socialTitle}</SocialTitle>
				<SocialText><RichText content={socialText}/></SocialText>
				{signUpLink?.text &&
					<SignUp>
						<SignUpButton bgColor='dteal' link={signUpLink.link}>
							{signUpLink.text}
						</SignUpButton>
					</SignUp>	
				}
				<SocialIcons>
					<SocialIcon 
						link={{
							linkType: 'external', 
							url: facebookUrl,
							blank: true
						}}>
						<Facebook/>
					</SocialIcon>	
					<SocialIcon 
						link={{
							linkType: 'external', 
							url: instagramUrl,
							blank: true
						}}>
						<Instagram />
					</SocialIcon>	
					<SocialIcon 
						link={{
							linkType: 'external', 
							url: linkedInUrl,
							blank: true
						}}>
						<LinkedIn />
					</SocialIcon> 		
				</SocialIcons>
			</Social>
			<Bottom>
				<LogoContainer>
					<FooterLogo />
				</LogoContainer>
				{footerMenu.map(column => (
					<MenuColumn key={column.title}>
						<ColumnTitle className='sub'>{column.title}</ColumnTitle>
						{column.links.map(link => (
							<MenuItem key={link.text} link={link.link}>
								<span>{link.text}</span>
							</MenuItem>
						))}
					</MenuColumn>
				)) }
				<MenuMobile>
					<MenuToggle 
						className='h5' 
						onClick={() => setMenuOpen(!menuOpen)}
					>
						{menuOpen ? 'hide' : 'show'} footer links 
						<Chevron menuOpen={menuOpen}/>
					</MenuToggle>
					<SlideDown>
						{menuOpen &&
						<MenuItemsMobile>
							{footerMenu.map(column => (
								<MenuItemGroup key={column.title}>
									<ColumnTitle className='sub'>{column.title}</ColumnTitle>
									{column.links.map(link => (
										<MenuItem key={link.text} link={link.link}>
											<span>{link.text}</span>
										</MenuItem>
									))}
								</MenuItemGroup>
							)) }
						</MenuItemsMobile>
						}
					</SlideDown>
				</MenuMobile>
				<Badges>
					<Charity src="/images/Charity.png" alt="Registered-charity-logo" />
					<NSMHS src="/images/NSMHS.png" alt="NSMHS-logo"/>
				</Badges>
				<Disclaimer className='b2'>
					<RichText content={disclaimer}/>
				</Disclaimer>
				<FinePrintMenu className='b2'>
					{finePrintMenu.map(link => (
						<FinePrintMenuLink link={link.link} key={link.text}>
							{link.text}
						</FinePrintMenuLink>
					))}
					<SanityAck className='b2'>
						Structured Content powered by <a href="https://www.sanity.io" target='_blank' rel="norefferer noreferrer">Sanity.io</a>
					</SanityAck>
					<Copyright>© <SiteTitle>PANDA </SiteTitle>{new Date().getFullYear()}</Copyright>
				</FinePrintMenu>
				<SanityAckMob className='b2'>Structured Content powered by <a href="https://www.sanity.io" target='_blank' rel="noreferrer">Sanity.io</a></SanityAckMob>
			</Bottom>
		</Wrap>
	)
}

const Wrap = styled.footer`
	color: var(--beige20);
	button{
		grid-column: span 12;
		text-align: left;
	}
`
const Container = styled.div`
	margin-bottom: var(--m);
	grid-column: span 12;
	text-align: center;
`
const Acknowledgement = styled(Section)`
	background-color: var(--dteal);
	padding: var(--m) 0;
	${mobile}{
		padding: var(--l) 0;
	}
`
const Flags = styled.div`
	grid-column: 1/13;
	margin-bottom: var(--m);
	display: flex;
	justify-content: center;
`
const Flag = styled(Image)`
	width: 66px;
	margin-right: var(--xs);
	:last-of-type{
		margin-right: 0px;
	}
`
const AckText = styled(Container)`
	grid-column: 2/12;
`
const AckButtonContainer = styled(Container)`
	margin-bottom: 0px;
`
const Social = styled(Section)`
	background-color: var(--teal);
	padding: 100px 0;
	text-align: center;
	${mobile}{
		padding: 64px 0;
	}
`
const SocialTitle = styled.h3`
	grid-column: span 12;
	margin-bottom: var(--m);
	${mobile}{
		margin-bottom: var(--s);
	}
`
const SocialText = styled(Container)`
	grid-column: 4/10;
	${tablet}{
		grid-column: span 12;
		margin-bottom: var(--l);
	}
`
const SignUp = styled(Container)`
	margin-bottom: var(--xl);
`
const SignUpButton = styled(Button)`

`
const SocialIcons = styled.div`
	grid-column: span 12;
	display: flex;
	justify-content: center;
`
const SocialIcon = styled(SanityLink)`
	margin-right: var(--xl);
	display: block;
	width: 50px;	
	:last-of-type {
		margin-right: 0px;
	}
	${mobile}{
		width: 60px;
	}
`
const Bottom = styled(Section)`
	background-color: var(--dteal);
	padding: var(--l) 0 var(--m);
	${mobile}{
		padding-bottom: var(--l);
	}
`
const LogoContainer = styled.div`
	grid-column: 1/9;
	grid-row: 1;
	margin-bottom: var(--m);
	${mobile}{
		grid-column: span 12;
		text-align: center
	}
`
const FooterLogo = styled(LogoWhite)`
	width: 170px;
	${mobile}{
		width: 142px;
	}
`
const MenuColumn = styled.div`
	grid-column: span 2;
	grid-row: 2;
	display: flex;
	flex-direction: column;
	margin-bottom: var(--xl);
	${tablet}{
		grid-column: span 3;
	}
	${mobile}{
		display: none;
	}
`
const MenuMobile = styled.div`
	display: none;
	${mobile}{
		display: block;
		grid-column: span 12;
		margin-bottom: var(--l);
	}
` 
const MenuToggle = styled.button`
	display: flex;
	justify-content: space-between;
	align-items: center;
	width: 100%;
` 
const Chevron = styled(ChevronDown)`
	width: 16px;
	margin-right: var(--xxs);
	transform: ${props => props.menuOpen ? 'rotate(180deg)' : 'rotate(0deg)'};
	transition: transform 0.2s;
`
const MenuItemsMobile = styled.div`
	margin: var(--l) 0 var(--l);
` 
const MenuItemGroup = styled.div`
	margin-bottom: var(--l);
`
const ColumnTitle = styled.div`
	margin-bottom: var(--xs);
`
const MenuItem = styled(SanityLink)`
	margin-bottom: var(--xs);
	display: block;
	>span{
		position: relative;
		:after {
			position: absolute;
			bottom: -1px;
			left: 0;
			right: 0;
			background-color: transparent;
			height: 2px;
			content: '';
			transition: 0.2s background-color;
		}
	}
	:hover {
		>span{
			:after {
				background-color: var(--bteal);
			}
		}
	}
`
const Badges = styled.div`
	grid-column: 9/13;
	grid-row: 1/3;
	display: flex;
	align-items: center;
	flex-direction: column;
	margin-bottom: var(--xl);
	${tablet}{
		grid-column: span 12;
		grid-row: 3;
		margin-bottom: var(--m);
		align-items: start;
	}
	${mobile}{
		align-items: center;
	}
`
const Charity = styled.img`
	width: 100%;
	max-width: 200px;
	mix-blend-mode: lighten;
	margin-bottom: var(--xs);
	${mobile}{
		max-width: 150px;
	}
`
const NSMHS = styled.img`
	width: 100%;
	max-width: 242px;
`
const Disclaimer = styled.div`
	color: var(--dteal60);
	grid-column: 2/12;
	grid-row: 3;
	text-align: center;
	margin: 0 -30px var(--s); 
	${desktop}{
		grid-column: span 12;
		margin: 0 0 var(--s);
		grid-row: 4;
	}
	${mobile}{
		margin-bottom: var(--l);
	}
`
const FinePrintMenu = styled.div`
	grid-column: span 12;
	display: flex;
	justify-content: center;
	${desktop}{
		grid-row: 5;
	}
	${mobile}{
		justify-content: space-between;
		margin-bottom: var(--xxs);
	}
`
const FinePrintMenuLink = styled(SanityLink)`
	margin-right: var(--l);
	display: block;
	${mobile}{
		margin-right: 0px;
	}
`
const Copyright = styled.div`
`
const SiteTitle = styled.span`
	${mobile}{
		display: none;
	}
`
const SanityAck = styled.div`
	margin-right: var(--l);
	a {
		text-decoration: underline;
	}
	${mobile}{
		display: none;
	}
`
const SanityAckMob = styled(SanityAck)`
	display: none;
	${mobile}{
		display: block;
		grid-column: span 12;
	}
`
Footer.propTypes = {
	className: PropTypes.string,
}

export default Footer
